import { Box, Grid, GridItem } from '@chakra-ui/react';
import Footer from '@components/Footer';
import { isValidElement } from 'react';
import AccessibilityTool from '@components/AccessibilityTool';

const getGridProperties = (hasHeader, hasBreadcrumbs, hasNav, hasAside, hasMain) => {
  const gridItems = {
    header: {
      size: '90px',
      area: 'header'
    },
    breadcrumbs: {
      size: '64px',
      area: 'breadcrumbs'
    },
    nav: {
      size: '80px',
      area: 'nav'
    },
    aside: {
      size: '300px',
      area: 'aside'
    },
    main: {
      size: 'minmax(0, 1fr)',
      area: 'main'
    }
  };
  const templateColumns = [];
  const templateRows = [];
  let templateAreas = '';
  let headerAreas = '';
  let breadcrumbsAreas = '';
  let columnAreas = '';

  if (hasNav) {
    templateColumns.push(gridItems.nav.size);
    columnAreas = columnAreas.concat(gridItems.nav.area);
  }

  if (hasAside) {
    templateColumns.push(gridItems.aside.size);
    columnAreas = columnAreas.concat(' ', gridItems.aside.area);
  }

  if (hasMain) {
    templateColumns.push(gridItems.main.size);
    columnAreas = columnAreas.concat(' ', gridItems.main.area);
  }

  if (hasHeader) {
    templateRows.push(gridItems.header.size, gridItems.main.size);
    const columns = templateColumns.length || 1;
    headerAreas = Array(columns).fill(gridItems.header.area).join(' ');
  }

  if (hasBreadcrumbs) {
    if (hasHeader) {
      templateRows.splice(1, 0, gridItems.breadcrumbs.size);
    } else {
      templateRows.push(gridItems.breadcrumbs.size, gridItems.main.size);
    }
    const columns = templateColumns.length || 1;
    breadcrumbsAreas = Array(columns).fill(gridItems.breadcrumbs.area).join(' ');
  }

  templateAreas = `
      '${headerAreas}'
      '${breadcrumbsAreas}'
      '${columnAreas}'
    `;

  templateAreas = templateAreas.replace(/''/g, '');

  return {
    gridItems,
    templateColumns,
    templateRows,
    templateAreas
  };
};

const Layout = ({ children, header, breadcrumbs, nav, aside }) => {
  const hasHeader = isValidElement(header);
  const hasBreadcrumbs = isValidElement(breadcrumbs);
  const hasNav = isValidElement(nav);
  const hasAside = isValidElement(aside);
  const hasMain = isValidElement(children);
  const { gridItems, templateColumns, templateRows, templateAreas } = getGridProperties(hasHeader, hasBreadcrumbs, hasNav, hasAside, hasMain);
  const stickyTopPosition = hasHeader ? gridItems.header.size : 0;

  return (
    <>
      <Grid className="app-wrapper" minHeight='100vh' gridTemplateColumns={templateColumns.join(' ')} gridTemplateRows={templateRows.join(' ')} gridTemplateAreas={`${templateAreas}`}>
        {hasHeader && (
          <GridItem
            as='header'
            gridArea={gridItems.header.area}
            borderBottom='1px'
            borderColor='gray.200'
            position='fixed'
            top='0'
            left='0'
            right='0'
            bg='white'
            zIndex='sticky'
            height='90px'
          >
            {header}
          </GridItem>
        )}

        {hasBreadcrumbs && <GridItem gridArea={gridItems.breadcrumbs.area}>{breadcrumbs}</GridItem>}

        {hasNav && (
          <GridItem as='nav' gridArea={gridItems.nav.area} bg='gray.50' borderRight='1px' borderColor='gray.200'>
            <Box position='sticky' top={stickyTopPosition} zIndex='sticky'>
              {nav}
            </Box>
          </GridItem>
        )}

        {hasAside && (
          <GridItem as='aside' gridArea={gridItems.aside.area} borderRight='1px' borderColor='gray.200'>
            <Box position='sticky' top={stickyTopPosition}>
              {aside}
            </Box>
          </GridItem>
        )}

        {hasMain && (
          <GridItem as='main' gridArea={gridItems.main.area}>
            {children}
          </GridItem>
        )}

      </Grid>
      <Footer />

      <AccessibilityTool />
    </>
  );
};

export default Layout;
