const pages = ['club', 'istoric', 'conducere', 'contact', 'nominalizari', 'gimnastica-aerobica-agrement', 'informatii-publice', 'angajari'];

const sections = [
  'sectii',
  'atletism',
  'gimnastica-artistica',
  'gimnastica-aerobica',
  'gimnastica-ritmica',
  'haltere',
  'judo',
  'kaiac',
  'modelism-auto',
  'natatie',
  'tenis',
  'tenis-de-masa',
  'tir',
  'volei'
];
const routesCollection = {
  sectii: 'sectii',
  pages,
  sections,
  fotoSubsections: [...sections, 'gimnastica-baieti', 'csm-arad']
};

export { routesCollection };
