import atletism from '@assets/images/sections/atletism.jpeg';
import gimnasticaArtistica from '@assets/images/sections/gimnastica-artistica.jpg';
import gimnasticaAerobica from '@assets/images/sections/gimnastica-aerobica.jpeg';
import gimnasticaRitmica from '@assets/images/sections/gimnastica-ritmica.jpeg';
import haltere from '@assets/images/sections/haltere.jpeg';
import judo from '@assets/images/sections/judo.jpg';
import kaiac from '@assets/images/sections/kaiac.jpeg';
import natatie from '@assets/images/sections/natatie.jpeg';
import tenis from '@assets/images/sections/tenis.jpeg';
import tenisDeMasa from '@assets/images/sections/tenis-de-masa.jpg';
import tir from '@assets/images/sections/tir.jpeg';
import volei from '@assets/images/sections/volei.jpeg';
import modelismAuto from '@assets/images/sections/modelism-auto.jpeg';

export class SectionsUtils {
  static getSectionsId() {
    return {
      atletism: 3,
      'gimnastica-aerobica': 8,
      'gimnastica-artistica': 9,
      'gimnastica-ritmica': 13,
      haltere: 7,
      judo: 11,
      kaiac: 14,
      natatie: 12,
      tenis: 5,
      'tenis-de-masa': 6,
      tir: 4,
      volei: 10,
      'modelism-auto': 17,
      csm: 2,
      uncategorized: 1
    };
  }

  static getSectionImageBackground(sectionId) {
    const sectionsIds = SectionsUtils.getSectionsId();

    switch (sectionId) {
      case sectionsIds.atletism:
        return atletism;
      case sectionsIds.tir:
        return tir;
      case sectionsIds.tenis:
        return tenis;
      case sectionsIds['gimnastica-artistica']:
        return gimnasticaArtistica;
      case sectionsIds['gimnastica-aerobica']:
        return gimnasticaAerobica;
      case sectionsIds['gimnastica-ritmica']:
        return gimnasticaRitmica;
      case sectionsIds['tenis-de-masa']:
        return tenisDeMasa;
      case sectionsIds.haltere:
        return haltere;
      case sectionsIds.volei:
        return volei;
      case sectionsIds.judo:
        return judo;
      case sectionsIds.natatie:
        return natatie;
      case sectionsIds.kaiac:
        return kaiac;
      case sectionsIds['modelism-auto']:
        return modelismAuto;
      default:
        return null;
    }
  }

  static getSections() {
    const sectionsIds = SectionsUtils.getSectionsId();

    return {
      toate: { name: 'toate', label: 'Toate' },
      [sectionsIds.atletism]: { name: 'atletism', label: 'Atletism' },
      [sectionsIds['gimnastica-aerobica']]: {
        name: 'gimnastica aerobica',
        label: 'Gimnastică aerobică'
      },
      [sectionsIds['gimnastica-artistica']]: {
        name: 'gimnastica artistica',
        label: 'Gimnastică artistică'
      },
      [sectionsIds['gimnastica-ritmica']]: {
        name: 'gimnastica ritmica',
        label: 'Gimnastică ritmică'
      },
      [sectionsIds.haltere]: { name: 'haltere', label: 'Haltere' },
      [sectionsIds.judo]: { name: 'judo', label: 'Judo' },
      [sectionsIds.kaiac]: { name: 'kaiac', label: 'Kaiac' },
      [sectionsIds.natatie]: { name: 'natatie', label: 'Natație' },
      [sectionsIds.tenis]: { name: 'tenis', label: 'Tenis' },
      [sectionsIds['tenis-de-masa']]: {
        name: 'tenis de masa',
        label: 'Tenis de masă'
      },
      [sectionsIds.tir]: { name: 'tir', label: 'Tir' },
      [sectionsIds.volei]: { name: 'volei', label: 'Volei' },
      [sectionsIds.csm]: { name: 'csm', label: 'Csm' },
      [sectionsIds['modelism-auto']]: {
        name: 'modelism auto',
        label: 'Modelism auto'
      },
      [sectionsIds.uncategorized]: {
        name: 'uncategorized',
        label: 'Fara categorie'
      }
    };
  }

  static getSectionNameById(sectionId) {
    const sections = SectionsUtils.getSections();
    return sections[sectionId]?.name;
  }

  static getSectionLabelById(sectionId) {
    if (!sectionId) {
      return null;
    }
    const sections = SectionsUtils.getSections();
    return sections[sectionId]?.label;
  }

  static getSectionSlugById(sectionId) {
    const sections = SectionsUtils.getSections();

    return sections[sectionId]?.name.replace(/\s+/g, '-');
  }

  static getSectionIdBySlug(slug) {
    if (slug === 'toate') {
      return 0;
    }

    const sections = SectionsUtils.getSectionsId();

    return sections[slug];
  }

  static computeArticleLink = (slug, categId, postId) => {
    return `/stiri/${SectionsUtils.getSectionSlugById(categId)}/${slug}-${postId}`;
  };

  static getSectionBreadcrumbsById(lavel = 0, sectionId = null, postTitle = '', isSection = true) {
    const pagePath = isSection ? null : '/stiri';
    const pageLabel = isSection ? 'Secții' : 'Știri';

    const pages = [
      {
        label: 'Home',
        path: '/',
        isCurrent: lavel === 0
      },
      {
        label: pageLabel,
        path: lavel > 1 ? pagePath : '',
        isCurrent: lavel === 1
      }
    ];

    if (lavel > 1 && sectionId) {
      pages.push({
        label: SectionsUtils.getSectionLabelById(sectionId),
        path: `${pagePath}/${SectionsUtils.getSectionSlugById(sectionId)}`,
        isCurrent: lavel === 2
      });
    }

    if (lavel === 3) {
      pages.push({
        label: postTitle,
        path: null,
        isCurrent: lavel === 3
      });
    }

    return pages;
  }
}
