import istoric from '@assets/docs/35ani.pdf';
import certificat from '@assets/docs/certificat.pdf';
import legea69 from '@assets/docs/legea69.pdf';
import norme from '@assets/docs/norme-financiare.pdf';

const sectii = {
  label: 'Secții',
  path: '#',
  children: [
    {
      label: 'Atletism',
      path: '/sectii/atletism',
    },
    {
      label: 'Gimnastică artistică',
      path: '/sectii/gimnastica-artistica',
    },
    {
      label: 'Gimnastică aerobică',
      path: '/sectii/gimnastica-aerobica',
    },
    {
      label: 'Gimnastică ritmică',
      path: '/sectii/gimnastica-ritmica',
    },
    {
      label: 'Haltere',
      path: '/sectii/haltere',
    },
    {
      label: 'Judo',
      path: '/sectii/judo',
    },
    {
      label: 'Kaiac',
      path: '/sectii/kaiac',
    },
    {
      label: 'Modelism auto',
      path: '/sectii/modelism-auto',
    },
    {
      label: 'Natație',
      path: '/sectii/natatie',
    },
    {
      label: 'Tenis',
      path: '/sectii/tenis',
    },
    {
      label: 'Tenis de masă',
      path: '/sectii/tenis-de-masa',
    },
    {
      label: 'Tir',
      path: '/sectii/tir',
    },
    {
      label: 'Volei',
      path: '/sectii/volei',
    },
  ],
};

const pages = [
  {
    label: 'Home',
    path: '/',
  },
  {
    label: 'Despre club',
    path: '/club',
    children: [
      {
        label: 'Istoric',
        path: istoric,
        isExternal: true,
        isPdf: true,
      },
      {
        label: 'Conducere',
        path: '/club/conducere',
      },
      {
        label: 'Contact',
        path: '/club/contact',
      },
    ],
  },
  {
    label: 'Știri',
    path: '/stiri',
  },
  {
    label: 'Legislație',
    path: '#',
    children: [
      {
        label: 'C.I. sportivă',
        path: certificat,
        isExternal: true,
      },
      {
        label: 'Legea 69/2000',
        path: legea69,
        isExternal: true,
      },
      {
        label: 'Norme financiare',
        path: norme,
        isExternal: true,
      },
    ],
  },
  {
    label: 'Nominalizări',
    path: '/nominalizari',
  },
  {
    label: 'Agrement',
    path: '/agrement',
  },
  {
    label: 'Informații publice',
    path: '/informatii-publice',
  },
  {
    label: 'Galerie foto',
    path: '/galerie-foto',
  },
];

const utilsLinks = [
  { label: 'Atletism', path: 'http://www.fra.ro/' },
  { label: 'Fotbal', path: 'http://frf.ro/' },
  { label: 'Judo', path: 'http://www.frjudo.ro/' },
  { label: 'Handbal', path: 'http://www.frh.ro/' },
  { label: 'Volei', path: 'http://www.frvolei.ro/' },
  { label: 'Tenis de masă', path: 'http://www.frtenismasa.ro/' },
  { label: 'Tenis', path: 'http://www.frt.ro/' },
  { label: 'Natație', path: 'http://www.swimming.ro/' },
  { label: 'Kaiac Canoe', path: 'http://www.kaiac.ro/' },
];

const detaliiSectie = {
  atletism: {
    location: 'Str. Dacilor nr. 9 (Stadionul Gloria Arad)',
    coaches: ['Oprea Ioan – 0745074095', 'Miclea Constantin – 0744758909'],
  },
  gimnasticaArtistica: {
    location: 'Str. Dacilor nr. 9 (Stadionul Gloria Arad)',
    coaches: ['Hegyi Ciprian – 0741228023', 'Franiov Carina – 0744784991'],
  },
  gimnasticaAerobica: {
    location: 'Str. Vasile Ureche 11-13',
    coaches: ['Farcuţa Elza – 0724285360'],
  },
  gimnasticaRitmica: {
    location: 'Str. Vasile Ureche 11-13',
    coaches: ['Chiriac Daniela – 0747609935'],
  },
  haltere: {
    location: 'Str. Mihai Eminescu nr. 28',
    coaches: ['Jigău Adrian – 0746148346', 'Sebeşan Pavel – 0722719246', 'Crişan Adrian – 0727838340'],
  },
  judo: {
    location: 'Aleea Predeal nr. 8',
    coaches: ['Botas Gabriela - 0745770046', 'Drimbe Dorin – 0727187179'],
  },
  kaiac: {
    location: 'Kaiac-Canoe Baza Nautică LPS Arad',
    coaches: ['Alexandru Stoian'],
  },
  modelismAuto: {
    location: null,
    coaches: [],
  },
  natatie: {
    location: 'Bazinul "DELFINUL" Arad',
    coaches: ['Barta Daria – 0721895620', 'Bitang Viorel – 0723491231', 'Liptak Martin – 0722621597'],
  },
  tenis: {
    location: null,
    coaches: ['Freer Anela - 0745165037'],
  },
  tenisDeMasa: {
    location: 'Str. Mihai Eminescu nr. 28',
    coaches: ['Dodean Cristian – 0726303016', 'Fistyulof Andreea - 0745688194', 'Muntean Patriciu – 0748800834'],
  },
  tir: {
    location: 'Poligon de tir sportiv "Babii Sorin"- Str. C. Coposu FN',
    coaches: ['Pop Gheorghe – 0740186415', 'Toth Monica – 0754789038', 'Buda Alexandra – 0725528333'],
  },
  volei: {
    location: 'Beach Volei – SC. Generală nr. 18',
    coaches: [],
  },
};

const clubLinks = [
  {
    label: 'Istoric',
    path: istoric,
    isExternal: true,
  },
  {
    label: 'Conducere',
    path: '/club/conducere',
  },
  {
    label: 'Galerie foto',
    path: '/galerie-foto',
  },
];

const contact = {
  phone: '0040 257 280347',
  fax: '0040 257 270956',
  email: ['csm.arad@sport.gov.ro', 'csmarad@yahoo.com'],
  address: 'Str. Lucian Blaga nr. 20, 310023, Arad, România',
  gmap: 'https://www.google.com/maps/embed?pb=!4v1655539367410!6m8!1m7!1sd4h9M6RqS2aVLz2LoGlniw!2m2!1d46.17475862778457!2d21.31356060251992!3f66.56448420872528!4f8.790164650094255!5f0.7820865974627469',
  sections: [
    {
      label: 'Atletism',
      coaches: detaliiSectie.atletism.coaches,
    },
    {
      label: 'Gimnastică artistică',
      coaches: detaliiSectie.gimnasticaArtistica.coaches,
    },
    {
      label: 'Gimnastică aerobică',
      coaches: detaliiSectie.gimnasticaAerobica.coaches,
    },
    {
      label: 'Gimnastică ritmică',
      coaches: detaliiSectie.gimnasticaRitmica.coaches,
    },
    {
      label: 'Haltere',
      coaches: detaliiSectie.haltere.coaches,
    },
    {
      label: 'Judo',
      coaches: detaliiSectie.judo.coaches,
    },
    {
      label: 'Kaiac',
      coaches: detaliiSectie.kaiac.coaches,
    },
    {
      label: 'Modelism auto',
      coaches: detaliiSectie.modelismAuto.coaches,
    },
    {
      label: 'Natație',
      coaches: detaliiSectie.natatie.coaches,
    },
    {
      label: 'Tenis',
      coaches: detaliiSectie.tenis.coaches,
    },
    {
      label: 'Tenis de masă',
      coaches: detaliiSectie.tenisDeMasa.coaches,
    },
    {
      label: 'Tir',
      coaches: detaliiSectie.tir.coaches,
    },
    {
      label: 'Volei',
      coaches: detaliiSectie.volei.coaches,
    },
  ],
};

const Data = {
  site: {
    name: 'Club Sportiv Municipal Arad',
    shortName: 'CSM Arad',
    social: {
      facebook: 'https://www.facebook.com/csmarad.ro/',
      youtube: 'https://www.youtube.com/channel/UCRfxSgAo8dqlOljk9bcQMgA',
    },
  },
  pages,
  sectii,
  detaliiSectie,
  utilsLinks,
  contact,
  clubLinks,
};

export { Data };
