import { Box, Container, Link, SimpleGrid, Stack, Text, VisuallyHidden, List, ListItem, chakra } from '@chakra-ui/react';
import { Data } from '@utils/Data';
import { FaFacebook, FaYoutube } from 'react-icons/fa';
import { Link as NavLink } from 'react-router-dom';

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={4}>
      {children}
    </Text>
  );
};

const SocialButton = ({ children, label, href }) => {
  return (
    <Box
      as={Link}
      bg='transparent'
      color='blue.500'
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.6s ease'}
      fontSize='xl'
      isExternal
      _hover={{
        bg: 'blue.50',
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </Box>
  );
};

const Copyright = () => {
  const year = new Date().getFullYear();
  return (
    <Container as={Stack} maxW={'6xl'} py={4} direction={{ base: 'column', md: 'row' }} spacing={4} justify={{ md: 'space-between' }} align={{ md: 'center' }}>
      <Text fontSize='sm' color='gray.400'>
        &copy; Copyright {year} {Data.site.name}. Toate drepturile rezervate.
      </Text>
      <Stack direction={'row'} spacing={3}>
        <SocialButton label={'Facebook'} href={Data.site.social.facebook}>
          <FaFacebook />
        </SocialButton>
        <SocialButton label={'YouTube'} href={Data.site.social.youtube}>
          <FaYoutube />
        </SocialButton>
      </Stack>
    </Container>
  );
};

const Links = ({ isExternal, items }) => {
  return (
    <>
      {items.map((item) => {
        if (isExternal || !!item?.isExternal) {
          return (
            <Link href={item.path} key={item.path} isExternal={isExternal || !!item?.isExternal} fontSize='sm'>
              <chakra.span>{item.label}</chakra.span>
            </Link>
          );
        }

        return (
          <Link as={NavLink} to={item.path} key={item.path} isExternal={isExternal} fontSize='sm'>
          <chakra.span>{item.label}</chakra.span>
          </Link>
        );
      })}
    </>
  );
};

const Footer = () => {
  return (
    <Box as='footer' bg='gray.50' color='gray.500'>
      <Container as={Stack} maxW={'6xl'} py={10}>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={8}>
          <Stack align={'flex-start'}>
            <ListHeader>Club</ListHeader>
            <Links isExternal={false} items={Data.clubLinks} />
          </Stack>

          <Stack align={'flex-start'}>
            <ListHeader>Secții CSM</ListHeader>
            <Links isExternal={false} items={Data.sectii.children} />
          </Stack>

          <Stack align={'flex-start'}>
            <ListHeader>Link-uri Federație</ListHeader>
            <Links isExternal items={Data.utilsLinks} />
          </Stack>

          <Stack align={'flex-start'}>
            <ListHeader>Contact</ListHeader>
            <List spacing={2} fontSize='sm'>
              <ListItem>
                <chakra.strong fontWeight='bold'>Adresa</chakra.strong>: <chakra.span>{Data.contact.address}</chakra.span>
              </ListItem>
              <ListItem>
                <chakra.strong fontWeight='bold'>Telefon</chakra.strong>: <chakra.span>{Data.contact.phone}</chakra.span>
              </ListItem>
              <ListItem>
                <chakra.strong fontWeight='bold'>Email</chakra.strong>: <chakra.span>{Data.contact.email[0]}</chakra.span>
              </ListItem>
            </List>
          </Stack>
        </SimpleGrid>
      </Container>

      <Box borderTopWidth={1} borderStyle={'solid'} borderColor='gray.200'>
        <Copyright />
      </Box>
    </Box>
  );
};

export default Footer;
