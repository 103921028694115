import logo from '@assets/images/logo.svg';
import logoGray from '@assets/images/logo-gray.svg';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { Box, Container, Flex, IconButton, Input, Link, Stack, useDisclosure } from '@chakra-ui/react';
import DesktopNav from '@components/Header/DesktopNav';
import MobileNav from '@components/Header/MobileNav';
import { Data } from '@utils/Data';
import { NavLink } from 'react-router-dom';

const Header = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  // const isMobile = useBreakpointValue({ base: true, md: false });
  const navigationItems = [...Data.pages.slice(0, 2), Data.sectii, ...Data.pages.slice(2)];

  const Logo = () => {
    const logoSrc = document.body.classList.contains('negative-contrast') ? logoGray : logo;
    return (
      <Box w={165}>
        <Link to='/' as={NavLink}>
          <img as='img' height='90px' width='165px' src={logoSrc} alt='Logo CSM Arad' loading='lazy' />
        </Link>
      </Box>
    );
  };

  const Toggle = () => {
    return <IconButton onClick={onToggle} icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />} variant={'ghost'} />;
  };

  return (
    <>
      <Flex color='blue.500'>
        <Container maxW='7xl'>
          <Flex alignItems='center'>
            <Flex flex={{ base: 1, lg: 'auto' }} ml={{ base: -2 }} display={{ base: 'flex', lg: 'none' }}>
              <Toggle />
            </Flex>
            <Flex flex={{ base: 1 }} justify={{ base: 'center', lg: 'space-between' }}>
              <Logo />

              <Flex display={{ base: 'none', lg: 'flex' }} ml={10} alignItems='end' flexDirection='column' justifyContent='space-between'>
                <Box w={60} mr={4} mt={3}>
                  <Input placeholder='caută articol' size='sm' />
                  {/* <Search /> */}
                </Box>
                <Box>
                  <DesktopNav items={navigationItems} />
                </Box>
              </Flex>
            </Flex>

            <Stack flex={{ base: 1, lg: 0 }} justify={'flex-end'} direction={'row'} spacing={{ base: 2, lg: 6 }}></Stack>
          </Flex>
        </Container>
      </Flex>
      <MobileNav items={navigationItems} onClose={onClose} isMenuOpen={isOpen} />
    </>
  );
};

export default Header;
