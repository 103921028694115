import Header from '@components/Header';
import Layout from '@components/Layout';
import Loader from '@components/Loader/Loader';
import { routesCollection } from '@pages/type';
import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

const Home = React.lazy(() => import('@pages/Home'));
const Post = React.lazy(() => import('@pages/Post'));
const Despre = React.lazy(() => import('@pages/Despre'));
const Agrement = React.lazy(() => import('@pages/Agrement'));
const Conducere = React.lazy(() => import('@pages/Conducere'));
const Contact = React.lazy(() => import('@pages/Contact'));
const GalerieFoto = React.lazy(() => import('@pages/GalerieFoto'));
const Album = React.lazy(() => import('@pages/GalerieFoto/Album'));
const Category = React.lazy(() => import('@pages/GalerieFoto/Category'));
const InformatiiPublice = React.lazy(() => import('@pages/InformatiiPublice'));
const Nominalizari = React.lazy(() => import('@pages/Nominalizari'));
const NotFound = React.lazy(() => import('@pages/NotFound'));
const Sectie = React.lazy(() => import('@pages/Sectie'));
const Stiri = React.lazy(() => import('@pages/Stiri'));

const sectiiRoutes = routesCollection.sections.map((route, index) => (
  <Route
    path={`/sectii/${route}`}
    key={index}
    element={
      <Layout header={<Header />}>
        <Sectie />
      </Layout>
    }
  />
));

const stiriCategoriesRoutes = routesCollection.sections.map((route, index) => (
  <Route
    path={`/stiri/${route}`}
    key={index}
    element={
      <Layout header={<Header />}>
        <Stiri />
      </Layout>
    }
  />
));

const Pages = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense fallback={<Loader isFull />}>
      <Routes>
        <Route
          path='/'
          element={
            <Layout header={<Header />}>
              <Home />
            </Layout>
          }
        />
        <Route
          path='/club'
          element={
            <Layout header={<Header />}>
              <Despre />
            </Layout>
          }
        />
        <Route
          path='/club/conducere'
          element={
            <Layout header={<Header />}>
              <Conducere />
            </Layout>
          }
        />
        <Route
          path='/informatii-publice'
          element={
            <Layout header={<Header />}>
              <InformatiiPublice />
            </Layout>
          }
        />
        <Route
          path='/club/contact'
          element={
            <Layout header={<Header />}>
              <Contact />
            </Layout>
          }
        />
        <Route
          path='/agrement'
          element={
            <Layout header={<Header />}>
              <Agrement />
            </Layout>
          }
        />

        <Route
          path='/nominalizari'
          element={
            <Layout header={<Header />}>
              <Nominalizari />
            </Layout>
          }
        />

        <Route
          path='/galerie-foto/:category'
          element={
            <Layout header={<Header />}>
              <Category />
            </Layout>
          }
        >
          <Route path='/galerie-foto/:category/:album' element={<Album />} />
        </Route>
        <Route
          path='/galerie-foto/'
          element={
            <Layout header={<Header />}>
              <GalerieFoto />
            </Layout>
          }
        />
        {sectiiRoutes}
        {stiriCategoriesRoutes}
        <Route
          path='/stiri/:category/:slug-:id'
          element={
            <Layout header={<Header />}>
              <Post />
            </Layout>
          }
        />
        <Route
          path='/stiri'
          element={
            <Layout header={<Header />}>
              <Stiri />
            </Layout>
          }
        />
        <Route path='*' element={<Layout header={<Header />}>{<NotFound />}</Layout>} />
      </Routes>
    </Suspense>
  );
};

export default Pages;
