import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Collapse, Flex, Icon, Link, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';

const MobileNav = ({ items, onClose, isMenuOpen }) => {
  useEffect(() => {
    document.body.classList.toggle('no-scrollable', isMenuOpen);
  }, [isMenuOpen]);

  return (
    <>
      {isMenuOpen && (
        <Box sx={{ width: '100%', height: '100%', bg: 'white', overflow: 'auto', position: 'fixed', top: '91px', left: 0, right: 0 }}>
          <Collapse in={isMenuOpen} animateOpacity>
            <Stack p={4} display={{ md: 'none' }}>
              {items.map((navItem) => (
                <MobileNavItem key={navItem.label} {...navItem} onClose={onClose} />
              ))}
            </Stack>
          </Collapse>
        </Box>
      )}
    </>
  );
};

const MobileNavItem = ({ label, children, path, onClose }) => {
  const { onToggle, isOpen } = useDisclosure();
  const handleItemClick = () => {
    onToggle();
    onClose();
  };
  return (
    <Stack spacing={4}>
      <Flex
        py={2}
        to={path ?? '#'}
        as={path !== '#' ? NavLink : 'div'}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none'
        }}
        onClick={(e) => {
          if ((path === '#' && children) || (path !== '#' && children.length > 0 && e.target.tagName.toLowerCase() === 'svg')) {
            onToggle();
          } else {
            onClose();
          }
        }}
      >
        <Text fontWeight={600} color='gray.600'>
          {label}
        </Text>
        {children && (
          <Icon
            onClick={(e) => {
              e.preventDefault();
              onToggle();
            }}
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack mt={2} pl={4} borderLeft={1} borderStyle={'solid'} borderColor='gray.200' align={'start'}>
          {children &&
            children.map((child) => (
              <Link as={NavLink} key={child.label} py={2} to={child.path} onClick={handleItemClick} isExternal={!!child.isExternal}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default MobileNav;
