import { ChakraProvider } from '@chakra-ui/react';
import '@fontsource/open-sans/700.css';
import '@fontsource/raleway/400.css';
import '@fontsource/raleway/500.css';
import Pages from '@pages';
import customTheme from 'theme/customTheme';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './index.scss';

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <ChakraProvider theme={customTheme}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <Pages />
          </Router>
        </QueryClientProvider>
      </ChakraProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('app'),
);
