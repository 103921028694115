import { extendTheme } from '@chakra-ui/react';

const colors = {
  red: {
    50: '#ffe3e5',
    100: '#feb6b9',
    200: '#f7888c',
    300: '#f2595e',
    400: '#ed2b32',
    500: '#d41218',
    600: '#a60b12',
    700: '#77060d',
    800: '#4a0205',
    900: '#200000',
  },
  blue: {
    50: '#ebebff',
    100: '#c4c6f0',
    200: '#9f9fe1',
    300: '#7879d3',
    400: '#5353c6',
    500: '#3939ac',
    600: '#2c2d87',
    700: '#1e2061',
    800: '#12133d',
    900: '#05051a',
  },
  brand: {
    50: '#ecefff',
    100: '#cbceeb',
    200: '#a9aed6',
    300: '#888ec5',
    400: '#666db3',
    500: '#4d5499',
    600: '#3c4178',
    700: '#2a2f57',
    800: '#181c37',
    900: '#080819',
  },
  primary: {
    50: '#faf6f6',
    100: '#eddcdc',
    200: '#ddbebd',
    300: '#ca9a98',
    400: '#c08582',
    500: '#b16966',
    600: '#a4504c',
    700: '#95332e',
    800: '#812924',
    900: '#5e1e1a',
  },
  secondary: {
    50: '#f0f0fa',
    100: '#d1d3e1',
    200: '#b3b6c9',
    300: '#9499b4',
    400: '#777c9f',
    500: '#5d6385',
    600: '#484d69',
    700: '#34374b',
    800: '#1e212e',
    900: '#080b14',
  },
};

const fonts = {
  heading: 'Raleway, sans-serif',
  body: 'Raleway, sans-serif',
};

const globalStyles = {
  styles: {
    global: {
      body: {
        color: '#4c4c4c',
      },
      'body.no-scrollable': {
        overflow: 'hidden',
      },
      '.main p + p': {
        pt: 5,
      },
      '.main p > img': {
        paddingTop: 5,
        paddingBottom: 5,
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      'blockquote p': {
        mt: 10,
        mb: 8,
        padding: 8,
        textStyle: 'italic',
        textAlign: 'center',
        color: 'gray.400',
        position: 'relative',
      },

      'blockquote p:before': {
        color: 'gray.400',
        content: 'open-quote',
        fontSize: '7xl',
        lineHeight: '0.1em',
        position: 'absolute',
        top: -5,
        left: '50%',
        transform: 'translateX(-50%)',
        marginBottom: 5,
      },
      '*:focus': {
        outline: '0 !important',
        boxShadow: 'none !important',
      },

      'span.chakra-breadcrumb__link: hover': {
        cursor: 'unset',
        textDecoration: 'none',
      },
      '.slick-dots': {
        bottom: '10px !important',
        zIndex: '999',
      },

      '.slick-dots li button:before': {
        color: 'var(--chakra-colors-red-400) !important',
      },
    },
  },
};

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const customTheme = extendTheme({
  ...globalStyles,
  colors,
  fonts,
  config,
});

export default customTheme;
