import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Flex, Icon, Link, Popover, PopoverContent, PopoverTrigger, Stack, Text, useDisclosure, chakra } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

const SubNav = ({ label, path, subLabel, onClose, isExternal }) => {
  return (
    <Link onClick={onClose} to={path} as={NavLink} role={'group'} display={'block'} p={2} rounded={'md'} _hover={{ bg: 'gray.50' }} isExternal={isExternal}>
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text transition={'all .3s ease'} _groupHover={{ color: 'blue.600' }} fontWeight={500} fontSize='sm'>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}
        >
          <Icon color={'blue.200'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const NavItem = ({ navItem }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const gutter = 8;

  return (
    <Box key={navItem.label}>
      <Popover trigger='hover' placement={'bottom-start'} closeDelay={500} isOpen={isOpen} gutter={0}>
        <PopoverTrigger>
          <Box
            p={[null, null, null, 1, 2]}
            tabIndex={0}
            _focus={{ outline: 'none', shadow: 'outline' }}
            onMouseEnter={onOpen}
            onMouseLeave={onClose}
            onFocus={onOpen}
            onBlur={onClose}
          >
            <Link
              to={navItem.path}
              as={NavLink}
              p={[1, 1, 1, 1, 2]}
              fontSize={'sm'}
              fontWeight={700}
              _hover={{
                textDecoration: 'none',
                color: 'gray.800'
              }}
            >
              <chakra.span>{navItem.label}</chakra.span>
            </Link>
          </Box>
        </PopoverTrigger>
        {navItem.children && (
          <PopoverContent
            onMouseEnter={onOpen}
            onMouseLeave={onClose}
            onFocus={onOpen}
            onBlur={onClose}
            _after={{
              content: "''",
              position: 'absolute',
              w: '100%',
              h: `${gutter}px`,
              top: `-${gutter}px`,
              left: 0
            }}
            border={0}
            boxShadow={'xl'}
            bg='white'
            p={4}
            roundedTop='none'
            roundedBottom='xl'
            minW={'sm'}
          >
            <Stack>
              {navItem.children.map((child) => (
                <SubNav key={child.label} onClose={onClose} {...child} />
              ))}
            </Stack>
          </PopoverContent>
        )}
      </Popover>
    </Box>
  );
};
const DesktopNav = ({ items }) => {
  return (
    <Stack direction={'row'} spacing={[null, null, null, 1, 4]}>
      {items.map((navItem, index) => (
        <NavItem navItem={navItem} key={index} />
      ))}
    </Stack>
  );
};

export default DesktopNav;
