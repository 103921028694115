import { Flex, Spinner } from '@chakra-ui/react';
import { ConditionalWrapper } from '@utils/Utils';

const Loader = ({ isFull = false }) => {
  return (
    <ConditionalWrapper
      condition={isFull}
      wrapper={(children) => (
        <Flex w='100%' h='100vh' justifyContent='center' alignItems='center'>
          {children}
        </Flex>
      )}
    >
      <Flex justifyContent='center'>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.100'
          color='red.300'
          size='xl'
        />
      </Flex>
    </ConditionalWrapper>
  );
};

export default Loader;
