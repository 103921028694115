import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { AiFillEye, AiOutlineBulb } from 'react-icons/ai';
import { BiFont, BiUndo, BiZoomIn, BiZoomOut } from 'react-icons/bi';
import { FaBarcode, FaWheelchair } from 'react-icons/fa';
import { FiLink2 } from 'react-icons/fi';
import { MdInvertColors } from 'react-icons/md';

const toggleClasses = {
  GRAYSCALE: 'grayscale',
  LIGHT_BACKGROUND: 'light-background',
  NEGATIVE_CONTRAST: 'negative-contrast',
  HIGH_CONTRAST: 'high-contrast',
}
const bodyClasses = {
  ...toggleClasses,
  LINKS_UNDERLINE: 'links-underline',
  READABLE_FONT: 'readable-font'
}

const removeToggleClasses = (currentClass, classes = toggleClasses,) => {
  const bodyClassList = document.body.classList;

  for (const [key, value] of Object.entries(classes)) {
    console.log('debyg', key, currentClass);
    (value !== currentClass || !currentClass) && bodyClassList.remove(value);
  }
}

const AccessibilityTool = () => {
  const [fontSize, setFontSize] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  const grayScaleHandle = () => {
    removeToggleClasses(bodyClasses.GRAYSCALE);
    document.body.classList.toggle(bodyClasses.GRAYSCALE);
  };

  const lightBackgroundHandle = () => {
    removeToggleClasses(bodyClasses.LIGHT_BACKGROUND);
    document.body.classList.toggle(bodyClasses.LIGHT_BACKGROUND);
  };

  const negativeContrastHandle = () => {
    removeToggleClasses(bodyClasses.NEGATIVE_CONTRAST);
    document.body.classList.toggle(bodyClasses.NEGATIVE_CONTRAST);
  };

  const highContrastHandle = () => {
    removeToggleClasses(bodyClasses.HIGH_CONTRAST);
    document.body.classList.toggle(bodyClasses.HIGH_CONTRAST);
  };

  const increaseFontSizeClickHandle = () => {
    setFontSize(prev => {
      if (prev === 90) return prev;
      return prev === null ? prev + 20 : prev + 10
    }
    );
  };

  const decreaseFontSizeClickHandle = () => {
    setFontSize(prev => {
      if (!prev || prev === 20) return null;
      return prev - 10
    }
    );
  };

  const resetHandle = () => {
    setFontSize(null);
    removeToggleClasses(null, bodyClasses);
  }

  useEffect(() => {
    const classList = document.body.classList;
    for (const [key, value] of Object.entries(classList)) {
      if (value?.startsWith('resize-font')) {
        classList.remove(value);
      }
    }

    fontSize && document.body.classList.add(`resize-font-${fontSize + 100}`);
  }, [fontSize]);


  const navLinks = [
    {
      name: 'Mărește textul',
      icon: <BiZoomIn />,
      clickHandle: increaseFontSizeClickHandle
    },
    {
      name: 'Micșoreaza textul',
      icon: <BiZoomOut />,
      clickHandle: decreaseFontSizeClickHandle
    },
    {
      name: 'Tonuri de gri',
      icon: <FaBarcode />,
      clickHandle: grayScaleHandle
    },
    {
      name: 'Contrast mare',
      icon: <MdInvertColors />,
      clickHandle: highContrastHandle
    },
    {
      name: 'Contrast negativ',
      icon: <AiFillEye />,
      clickHandle: negativeContrastHandle
    },
    {
      name: 'Fundal luminos',
      icon: <AiOutlineBulb />,
      clickHandle: lightBackgroundHandle
    },
    {
      name: 'Legaturi subliniate',
      icon: <FiLink2 />,
      clickHandle: () => document.body.classList.toggle(bodyClasses.LINKS_UNDERLINE)
    },
    {
      name: 'Font lizibil',
      icon: <BiFont />,
      clickHandle: () => document.body.classList.toggle(bodyClasses.READABLE_FONT)
    },
    {
      name: 'Resetează',
      icon: <BiUndo />,
      clickHandle: resetHandle
    }
  ]

  return (
    <>
      <Box
        as={Button}
        bottom="50px"
        left={0}
        position='fixed'
        background='blue.600'
        p={2}
        onClick={onOpen}
        zIndex="modal"
        className='ac-tool__trigger'
      >
        <Icon as={FaWheelchair} boxSize={6} color='white' />
      </Box>

      <Drawer
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent className='ac-tool'>
          <DrawerCloseButton />
          <DrawerHeader mt={8}>Instrumente de accesibilitate</DrawerHeader>

          <DrawerBody>
            <Stack direction='column' spacing={6} display="flex" flexDirection='column' alignItems="flex-start" mt={4}>
              {navLinks.map((item, index) => (
                <Button leftIcon={item.icon} color="gray.700" variant='link' onClick={item.clickHandle} key={index} sx={{ _hover: 'none' }}>
                  {item.name}
                </Button>
              ))}
            </Stack>
          </DrawerBody>

          <DrawerFooter>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default AccessibilityTool;
