import { SectionsUtils } from '@utils/SectionsUtils';
import { decode } from 'html-entities';

export const formatDate = (date) => {
  return date.slice(0, 10).split('-').reverse().join('.');
};

export const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

export const slugTrailingSlash = (pathname) => {
  if (!pathname) return '';

  return pathname.replace(/\//g, '');
};

export const reducerArray = (arr) => {
  return arr.reduce((accumulator, current) => {
    return accumulator.concat(current);
  }, []);
};

export const getPageBreadcrumbs = (pageName) => {
  return [
    {
      label: 'Home',
      path: '/',
      isCurrent: false,
    },
    {
      label: pageName,
      path: null,
      isCurrent: true,
    },
  ];
};

export const computeSlideData = (data, imageSize = 'large') => {
  if (!data) {
    return [];
  }

  return data?.data.map((item) => {
    const link = SectionsUtils.computeArticleLink(`${item.slug}`, item.categories[0], item.id);

    return {
      title: decode(item.title.rendered),
      categories: [...item.categories],
      images: item.fimg_url,
      link,
      date: formatDate(item.date),
    };
  });
};
